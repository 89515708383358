div.Popup{
    height:auto;
    width:300px;
    background: var(--secondary-main-color);
    cursor:default;
}

div.Popup *[level="error"]{
    color:var(--error-color)
}
div.Popup *[level="success"]{
    color:var(--success-color)
}

div.Popup *[level="warn"]{
    color:var(--warn-color)
}
div.Popup h2{
    width:100%;
    margin:0;
    padding:0;
    font-size:20px;
    text-align: center;
    padding:15px;
    box-sizing: border-box;
}

div.Popup p{
    width:100%;
    height:auto;
    font-size: 13px;
    text-align: center;
    margin:0;
    padding:20px;
    padding-left:40px;
    padding-right:40px;
    box-sizing: border-box;
}

div.Popup .buttonsContainer{

    height:70px;
    width:100%;

    text-align: justify;
    justify-content: space-evenly;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
    display: flex;
}

div.Popup .buttonsContainer .button{
    position: relative;
    display: inline-block;
    overflow: hidden;
    width:90px;
    height: 70px;
    padding:0;
    padding-top: 25px;
    padding-bottom: 25px;
    box-sizing: border-box;
    text-align: center;
    zoom: 1;
    vertical-align: middle;
    transition: all 0.2s ease;
    font-size: 18px;
    cursor: pointer;
}

div.Popup > img{
    padding-top:20px;
    box-sizing: border-box;
    height:60px;
    width:100%;
    object-fit: contain;
}

div.Popup .buttonsContainer .button:active{
    opacity: 0.5;
}