.ListSeparator{
    width:100%;
    height:30px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 15px;
}

.ListSeparator p{
    position: absolute;
    top:7.5px;
    left:30px;
    font-size:15px;
    margin:0;
    padding:0;
    color:var(--tint-color);
    opacity: 0.5;
}