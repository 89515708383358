.Blend{
    background: var(--main-color);
    display: block;
    position: absolute;
    pointer-events:none;
    
}

.Blend[pos="top"]{
    top:0;
    left:0;
    width:100%;
    height:100px;
    -webkit-mask-image:linear-gradient(0deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    mask-image:linear-gradient(0deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
}

.Blend[pos="left"]{
    top:0;
    left:0;
    width:50px;
    height:100%;
    -webkit-mask-image:linear-gradient(-90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    mask-image:linear-gradient(-90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
}

.Blend[pos="right"]{
    top:0;
    right:0;
    width:50px;
    height:100%;
    -webkit-mask-image:linear-gradient(90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    mask-image:linear-gradient(90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
}

.Blend[pos="bottom"]{
    bottom:0;
    left:0;
    width:100%;
    height:50px;
    -webkit-mask-image:linear-gradient(180deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    mask-image:linear-gradient(180deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
}