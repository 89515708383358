.NavMenu{
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width:100%;
    background: var(--secondary-main-color);
    border-radius: 20px;
    box-sizing: border-box;
}

.NavMenu > *{
    margin-left:env(safe-area-inset-left);
}

.NavMenu .Action{
    position: relative;
    height:60px;
    width:60px;
    padding:20px;
    cursor: pointer;
    
    
    box-sizing: border-box;
    transition: all 0.2s ease;
    
    border: 2px solid transparent;
}


.NavMenu .Action img{
    display: inline;
    height:20px;
    width:20px;
    object-fit: contain;
    vertical-align: middle;
    
}

.NavMenu .Action h3{
    display: none;
    font-size:14px;
    color:var(--tint-color);
    margin:2.5px;
    margin-left:12px;
    padding:0;
    vertical-align: middle;
}

.NavMenu .Action h3,
.NavMenu .Action img{
    opacity: 0.6;
}

.NavMenu .Action[data-selected="true"] h3,
.NavMenu .Action[data-selected="true"] img{
    opacity: 1;
}


.NavMenu .Action .indicator{
    transition: all 0.2s ease;
    opacity: 0;
    background: var(--tint-color);
    width:0px;
    height:0%;
    position: absolute;
    top:50%;
    left:-2px;
    transform: translateY(-50%);
    box-shadow: 0 0 10px 0.5px var(--tint-color);
}
.NavMenu .Action[data-selected="true"] .indicator{
    opacity: 1;
    height:100%;
    width:2px;
}


.NavMenu .Action:hover{
    opacity: 1;
}

.NavMenu .Action:active{
    opacity: 0.3;
}

.NavMenu + .viewport{
    transition: border-radius 0.2s ease  , filter 0.2s ease , left 0.2s ease !important;
    filter:brightness(1);
    left:0;
}

.NavMenu[data-selected="true"] + .viewport{
    left:calc(64px + env(safe-area-inset-left,0));
    border-radius: 20px;
    filter:brightness(0.5);
}
.NavMenu .Action{
    opacity: 0;
    
}

.NavMenu[data-selected="true"] .Action{
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
}

.NavMenu:not([data-selected="true"]) .Action{
    transition: opacity 0s ease-in-out 0.3s !important;
}






@media screen and (min-width:700px){
    .NavMenu .Action{
        width:200px;
        margin-top:10px;
        margin-bottom: 0;
        height:40px;
        width:200px;
        padding:10px;
    }
    .NavMenu .Action h3{
        display: inline-block;
    }

    .NavMenu{
        border-radius: 0;
    }

    .NavMenu[data-selected="true"] + .viewport{
        left:calc(154px + env(safe-area-inset-left,0)) !important;
        border-radius: 0px;
    }
}

.NavMenu img.hamburger{
    position: absolute;
    top:0px;
    left:0;
    height:60px;
    padding:5px;
    box-sizing: border-box;
    object-fit: contain;
    transition: all 0.2s ease;
    transform: rotate(0deg);
    z-index: 1;
}

.NavMenu img.hamburger:active{
    opacity: 0.5;
}

.NavMenu[data-selected="true"] img.hamburger[spin]{
    transform: rotate(90deg);
}

.NavMenu + .viewport:not([override="true"]) > .navbar > *:first-child,
.NavMenu + .viewport:not([override="true"]) > .viewport > .navbar > *:first-child{
    margin-left:calc( 60px + env(safe-area-inset-left,0) );
}



