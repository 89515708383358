.scrollView {
    width: 100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    overflow-x:hidden ;
    overflow-y: auto;

    padding-top:80px;
    padding-bottom: 120px;


    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  
    -webkit-mask-image:linear-gradient(0deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%);
    mask-image:linear-gradient(0deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%,rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%);
    -webkit-overflow-scrolling:touch;
    box-sizing: border-box;

}

.scrollView[x="true"]{
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: none;
    position: relative;
    height:auto;
    width:100%;
    padding:0;
    -ms-overflow-style: none;
    scrollbar-width: none;

    -webkit-mask-image:linear-gradient(90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80px, rgba(0,0,0,1) calc(100% - 80px), rgba(0,0,0,0) 100%);
    mask-image:linear-gradient(90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80px,rgba(0,0,0,1) calc(100% - 80px), rgba(0,0,0,0) 100%);

}

.scrollView[padding-x="true"]{
    padding-left:20px;
    padding-right: 20px;
}

.scrollView[padding-y="false"]{
    padding-top:0;
    padding-bottom: 0;
}

.scrollView[more-padding-y="true"]{

    padding-top:120px;
    padding-bottom: 180px;
}

.scrollView[blend="false"]{
    -webkit-mask-image:none;
    mask-image:none;
}

.scrollView[blend="less"]{
    -webkit-mask-image:linear-gradient(180deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80px, rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%);
    mask-image:linear-gradient(180deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80px,rgba(0,0,0,1) 75%, rgba(0,0,0,0) 100%);
}



.scrollView[x="true"]::-webkit-scrollbar{
    display: none;
}


