.responsive{
    position: relative;
    border:none;
    display: inline-block;
    vertical-align: top;
}




.responsive[data-responsive-size="1/1"]{
    width:100%;
}
.responsive[data-responsive-size="1/2"]{
    width:50%;
}
.responsive[data-responsive-size="1/3"]{
    width:33.33333%;
}
.responsive[data-responsive-size="2/3"]{
    width:66.66666%;
}
.responsive[data-responsive-size="1/4"]{
    width:25%;
}


.responsive[data-responsive-display="false"]{
    display: none;
}

.responsive[data-responsive-fullheight="true"]{
    height: auto;
    margin-bottom: 0 !important;
    margin-top:0 !important;
    border:red 0px solid;
    overflow: none;
    overflow-y: auto;
}

.flex-responsive-container{
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    height:100%;
    width:100%;
    margin: 0;
    border:none;
    position: absolute;
    top: 0;
    left:0;
}





