button,
div.button {
    background: transparent;
    color: var(--tint-color);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin: 0px;
    cursor: pointer;
    border:solid var(--tint-color) 1.5px;
    filter:brightness(1);
    transition: filter 0.2s ease;
    min-width:180px;
    height:45px;
    margin-top:50px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

button:hover,
div.button:hover{
    filter: brightness(0.9);
}

button:active,
div.button:active{
    filter:brightness(0.5);
}

button[level=error],
div.button[level=error]{
    border-color:var(--error-color);
    color:var(--error-color);
}

button[level=warn],
div.button[level=warn]{
    border-color:var(--warn-color);
    color:var(--warn-color);
}
button[level=success],
div.button[level=success]{
    border-color:var(--success-color);
    color:var(--success-color);
}

button[disabled],
div.button[disabled]{
    pointer-events:none;
    opacity:0.5;
}

button.round, button.circle,
div.button.round,div.button.circle{
    border-radius: 22.5px;
    background: none;
    color: var(--tint-color);
}

button[data-size="small"],
div.button[data-size="small"]{
    transform: scale(0.8);
}

button.circle,
div.button.circle{
    display: block;
    aspect-ratio: 1 !important;
    min-width: 45px !important;
    width: 45px !important ;
    max-width: 45px !important;
    height: 45px !important;
    margin:auto !important;
    max-height: 45px;
    min-height: 45px;
    font-size: 25px;
    border-radius: 50%;
    
}

.formgroup[data-size="1/3"] button.circle,
.formgroup[data-size="1/3"] button.circle.button{
    width: 45px !important ;
}

div.button{
    display: inline-block;
    vertical-align: middle !important;
    text-align: center;
    line-height: 43px;
}

div.button img,
button img{
    display: inline;
    position: relative;
    top:-8px;
    height: 30px;
    padding-top:12px;
    box-sizing: border-box;
    object-fit: contain;
    vertical-align: middle;
}
