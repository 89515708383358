div.SmallActionSheet{
    position: absolute;
    bottom:0%;
    left:50%;
    transform: translateX(-50%);

    background: var(--secondary-main-color);
    width:100%;
    max-width: 700px;
    height:auto;
    min-height: 100px;
    max-height: 85%;
    padding-bottom: env(safe-area-inset-bottom,0);
    z-index: 999999999;
    transition: all 0.2s ease;
}

div.SmallActionSheet:has(.scrollView){
    height:80%;
    max-height: none;
}

div.SmallActionSheet p{
    width:100%;
    padding:10%;
    padding-top:0px;
    padding-bottom:0px;
    box-sizing: border-box;
    text-align: center;
}


@media screen and (min-width:600px ) {
    div.SmallActionSheet{
        bottom:50%;
        transform:translate(-50%,-50%);
    }

    div.SmallActionSheet:has(.scrollView){
        height:70%;
        max-height: 800px;
    }
}

div.SmallActionSheet[background="main"]{
    background: var(--main-color);
}

div.SmallActionSheet[background="tint"]{
    background: var(--tint-color);
}

div.SmallActionSheet[background="tint"] *{
    color:var(--main-color);
}



div.SmallActionSheet .navbar h1{
    font-size: 25px;
}



