

input.Switch{
    position: relative;
    float:right;
    vertical-align: middle;
    display: inline-block;
    margin:40px;
    margin-left:20px;
    margin-right:20px;
    opacity: 1;
    height:32px;
    width:55px;
    cursor:pointer;
    border:solid 1px var(--tint-color);
    
}

input.Switch::before{
    position: absolute;
    content:' ';
    top:0;
    left:0;
    display: block;
    height:100%;
    width:100%;
    opacity: 1;
    background: var(--secondary-main-color);
    transition: all ease 0.2s;
    box-sizing: border-box;
    border:solid 0.5px var(--tint-color);
    font-size: 15px;
    padding-top: 8px;
    padding-left:4px;
    padding-right:2px;
}

input.Switch::after{
    position: absolute;
    display: block;
    left:4px;
    opacity: 1;
    top:4px;
    height:24px;
    width:24px;
    aspect-ratio: 1;
    content:' ';
    right:auto;
    background: var(--tint-color);
    transition: all ease 0.2s;
    box-shadow: 0 0 100px 0px var(--main-color);
}

input.Switch:checked::after{
    left:calc(100% - 3px - 26px);
    transition: all ease 0.2s;
}

input.Switch::before{
    background: var(--secondary-main-color);
    content:'OFF';
    text-align: right;
}

input.Switch:checked::before{
    content:'ON';
    text-align: left;
    background: var(--success-color);
    color:var(--tint-color);
    border-color: var(--success-color);
}

input.Switch[loading="true"]::after{
    background-image: url('./Ressources/LogoLoading\ 2_WT@2x.png');
    background-size:contain;
    filter:invert(100%);
    background-color: black;
    animation:spinner linear 0.7s infinite;
}