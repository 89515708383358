.ListItemExtension{
    position: relative;
    display: block;
    width: 100%;
    height:100px;
    margin-top:-20px;
    margin-bottom: -20px;

    background: transparent;
    color:var(--main-color);

    transition: all ease 0.2s;

    cursor: pointer;
}



.ListItemExtension h2{
    font-size:18px;
    padding:0;
    margin:0;
    position: absolute;
    top:32px;
    left:20px;
    transition: all ease 0.2s;
}

.ListItemExtension h3{
    font-size: 13px;
    padding:0;
    margin:0;
    top:52px;
    left:20px;
    opacity: 0.5;
    position: absolute;
    transition: all ease 0.2s;
}

.ListItemExtension .icon{
    height:20px;
    width:20px;
    position: absolute;
    top:40px;
    right:20px;
    transition: all ease 0.2s;
    transition: all 0.2s ease;
}

.ListItemExtension .icon[clickable="true"]:active{
    opacity: 0.5;
}

.ListItemExtension .icon img{
    height: 100%;
    width:100%;
    object-fit: contain;
    position: absolute;
    top:0;
    left:0;
}


.ListItemExtension >.fill{
    opacity: 0;
    background: var(--tint-color);
    height:0px;
    position: absolute;
    top:50%;
    left:0;
    width: 100%;
    transition: all ease 0.2s;
    transform: translateY(-50%);
}



.ListItemExtension[data-selected="true"] >.fill{
    opacity: 1;
    height: 140px;
    transition: all ease 0.2s;
}



.ListItemExtension[style]{
    animation: ListItemExtension_anim 0.2s ease forwards;
    opacity: 0;
}

@keyframes ListItemExtension_anim {
    0%{
        transform: scale(0.95);
        opacity: 0;
    }

    100%{
        opacity: 1;
        transform: scale(1);
    }
}





