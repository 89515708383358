.ProgressBar{
    position: relative;
    width: calc(100% - 50px);
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
    
} 

.ProgressBar .progress{
    position: absolute;
    left:0;
    height:1px;
    background: var(--tint-color);
    animation: loadbar-progress linear 0.7s infinite;
    width:30%;
}

.ProgressBar .bar{
    position: absolute;
    width:100%;
    height:1px;
    background: var(--terciary-main-color);
    overflow: hidden;
}

.ProgressBar .text{
    position: absolute;
    left:calc(100%  + 10px);
    top:-5px;
    font-size: 10px;
    color:var(--tint-color);
    opacity: 0.5;
}

@keyframes loadbar-progress {
    0%{
        left:-30%;
    }
    100%{
        left:100%;
    }
}

.ListItem .ProgressBar{
    position: absolute;
}

.ListItem .text-wrapper:has(.ProgressBar){
    margin-right: 0;
    width: calc(100% - 50px) !important;
    max-width: none;
}

.ListItem .thumbnail + .text-wrapper:has(.ProgressBar){
    width:calc(100% - 100px) !important;
    max-width: none;
}