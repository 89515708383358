.ElectionCirco{
    position: relative;
    display: inline-block;
    height:40px;
    width:170px;
    background: var(--secondary-main-color);
    margin:10px;
    margin-bottom: 0;
    margin-top: 20px;
    padding:10px;
}

.ElectionCirco .LightBulb{
    height: 10px;
    width:10px;
    border-radius: 50%;
    display: inline-block;
    margin:1px
}

.ElectionCirco .LightBulbContainer{
    position: absolute;
    right:10px;
    top:24px;
    
}

.ElectionCirco h2{
    font-size:14px;
    color:var(--tint-color);
    margin:0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ElectionCirco h3{
    font-size: 10px;
    color:var(--tint-color);
    opacity: 0.5;
    margin:0;
}

.ElectionCirco .ProgressBar{
    position: absolute;
    bottom:0;
    left:0;
}
.ElectionCirco[data-clickable="true"]:hover{
    transition: filter 0.2s ease;
    filter:brightness(1);
}
.ElectionCirco[data-clickable="true"]:hover{
    filter:brightness(1.3);
}
.ElectionCirco[data-clickable="true"]:active{
    filter:brightness(0.5);
}
