.NavBottomBar{
    position: absolute;
    bottom:0;
    left:7.5%;
    width:85%;
    height:60px;
    
    bottom: calc( 20px + env(safe-area-inset-bottom,0));
    background:var(--secondary-main-color);

    text-align: justify;
    justify-content: space-evenly;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
    display: flex;
}

.NavBottomBar .Action{
    position: relative;
    display: inline-block;
    font-size: 0; 
    line-height: 0;
    overflow: hidden;
    width:60px;
    height: 60px;
    zoom: 1;
    vertical-align: middle;
    transition: all 0.2s ease;
}

.NavBottomBar .Action img{
    position: absolute;
    top:0;
    left:0;
    height: 60px;
    width: 60px;
    padding:21.25px;
    object-fit: contain;
    display: block;
    box-sizing: border-box;
    transition: all 0.2s ease;
}

.NavBottomBar .Action h3{
    display: inline-block;
    position: absolute;
    top:30.5px;
    margin:0;
    padding:0;
    left:50px;

    font-size:12px;
    opacity: 0;
    color:var(--main-color);
    transition: all 0.2s ease;
    width:calc(100% - 70px);
    text-align: center;
    transition: all 0.2s ease;
}

.NavBottomBar .Action .indicator{
    position: absolute;
    top:0;
    left:50%;
    width:5px;
    height: 5px;
    opacity: 0;
    background: var(--tint-color);
    border-radius: 50%;
    transition: all 0.2s ease;
}

.NavBottomBar .Action[data-selected="true"]{
    width:120px;
    background: var(--tint-color);
}

.NavBottomBar .Action[data-selected="true"] h3{
    opacity: 1;
}
.NavBottomBar .Action[data-selected="true"] img{
    filter:invert(100%);
}
.NavBottomBar .Action[data-selected="true"] .indicator{
    width: 100%;
    opacity: 1;
}
