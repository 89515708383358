.formgroup{
  text-align: center;
  border:none;
  margin:0;
  margin-left: auto;
  margin-right: auto;
  padding:0px;
  padding-left: 7px;
  padding-right: 7px;
  box-sizing: border-box;

  height:auto;
  width:100%;
  display: block;
  table-layout: fixed;
  margin-top: 10px;
  margin-bottom: 10px;
  border:0;
}

.formgroup[data-size="1/2"]{
  display:inline-block;
  width:50%;
}

.formgroup[data-size="1/3"]{
  display:inline-block;
  width:33.3%;
}

.formgroup[data-size="1/4"]{
  display:inline-block;
  width:calc(25% - 1px);
}

.formgroup[data-size="2/3"]{
  display:inline-block;
  width:66.6%;
}

.formgroup[data-size="1/3"] button{
  min-width: 0px !important;
  width:180px;
  max-width:100% !important;
  width: 100% !important;
  margin-top:10px;
}

.formgroup[data-size="1/2"] button{
  min-width: 0px !important;
  width:150px;
  max-width:100% !important;
}