.ElectionResult{
    width:100%;
    height: 20px;
}
table:has( .ElectionResult) th{
   text-align: right;
   font-size: 10px;
   opacity: 0.5; 
   border-bottom:1px solid var(--tint-color);
   padding-bottom:5px;
   margin-bottom:5px;
}

table:has( .ElectionResult) th:nth-child(1){
    text-align: center;
 }
 table:has( .ElectionResult) th:nth-child(2){
    text-align: center;
 }
 table:has( .ElectionResult) th:nth-child(3){
    text-align: left;
 }


.ElectionResult > td {
    padding:5px;
    box-sizing: border-box;
    font-size: 10px;
    opacity: 0.5;
    text-align: right;
}
.ElectionResult .LightBulbContainer{
    opacity: 1;
    text-align: center;
    vertical-align: middle;
    width:20px;
}
.ElectionResult .LightBulb{
    height: 14px;
    width: 14px;
    display: inline-block;
    border-radius: 50%;
    background: var(--tint-color);
    vertical-align: middle;
    opacity: 1;
}

.ElectionResult > td.Name{
    font-size: 12px;
    opacity: 1;
    height:14px;
    text-align: left;
}

.ElectionResult .status{
    margin-top:1.5px;
    display: inline;
    margin-left:5px;
    position: absolute;
    font-size: 7px;
    vertical-align: middle;
    padding:1px;
    padding-left:3px;
    padding-right:3px;
}

.ElectionResult .status[data='ABSOLUTE_MAJORITY']{
    color:var(--main-color);
    background: var(--success-color);
}
.ElectionResult .status[data='RELATIVE_MAJORITY']{
    color:var(--main-color);
    background: var(--warn-color);
}
.ElectionResult .status[data='GROUP_ALLOWED']{
    color:var(--main-color);
    background: var(--info-color);
}

.ElectionResult .index{
    padding:0;
    width:5px;
}

