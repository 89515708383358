
div.ListItem{
    position: relative;
    display: table;
    width: 100%;
    height:100px;
    margin-top:-20px;
    margin-bottom: -20px;

    background: transparent;
    color:var(--main-color);

    transition: all ease 0.2s;

    cursor: pointer;
    
}

div.ListItem .text-wrapper{
    position: absolute;
    top:50%;
    left:30px;
    transform: translateY(-50%);
    max-width: 70%;
}

div.ListItem[disabled]{
    opacity: 0.5;
    pointer-events: none;
}

div.ListItem img.icon,
div.ListItem div.LightBulbMain{
    transition: all 0.2s ease;
    height:20px;
    width:20px;
    object-fit: contain;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left:30px;
    filter:invert(0%);
}

div.ListItem div.LightBulbMain{
    border-radius: 50%;
}


div.ListItem img.thumbnail{
    transition: all 0.2s ease;
    height:50px;
    width:50px;
    object-fit: cover;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left:15px;
    filter:invert(0%);
    background: var(--tint-color);
    border-radius: 50%;
}



div.ListItem h2{
    font-size:18px;
    padding:0;
    margin:0;
    transition: all ease 0.2s;
}

div.ListItem h3{
    font-size: 13px;
    padding:0;
    margin:0;
    transition: all ease 0.2s;
}

div.ListItem h2 + h3{
    opacity: 0.5;
}

div.ListItem p{
    font-size:10px;
    opacity: 0.5;
    color:var(--tint-color);
    transition: all ease 0.2s;
    margin: 0;
    margin-top:5px;
}

div.ListItem .circle{
    height:10px;
    width:10px;
    border: 1px solid var(--tint-color) ;
    background: transparent;
    border-radius: 50%;
    position: absolute;
    top:65px;
    left:30px;
    transition: all ease 0.2s;
    
}

div.ListItem .LightBulb{
    height:7px;
    width:7px;
    border-radius: 50%;
    position: absolute;
    top:calc(50% - 3.5px);
    left:10px;
}

div.ListItem .LightBulb[active="true"]{
    background: var(--success-color);
}
div.ListItem .LightBulb[active="false"]{
    background: var(--error-color);
}

div.ListItem .circle .fill{
    height:10px;
    width:10px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1.5px transparent solid;

    opacity: 0;
    transition: all ease 0.2s;
}

div.ListItem >.fill{
    opacity: 0;
    background: var(--tint-color);
    height:0px;
    position: absolute;
    top:50%;
    left:0;
    width: 100%;
    transition: all ease 0.2s;
    transform: translateY(-50%);
}

div.ListItem img.options{
    height: 10px;
    width:10px;
    object-fit: contain;
    position: absolute;
    top:calc(50% - 5px);
    right:30px;
    filter:invert(0);
    transition: all 0.2s ease;
}


div.ListItem[style]{
    animation: ListItem_anim 0.2s ease-in-out forwards;
    opacity: 0;
}


div.ListItem[level="error"] *{
    color:var(--error-color);
}

div.ListItem[level="error"] .fill{
    background: var(--error-color);
}



div.ListItem img.icon ~ .text-wrapper,
div.ListItem div.LightBulbMain ~ .text-wrapper{
    left:70px;
}

div.ListItem img.thumbnail ~ .text-wrapper{
    left:80px;
}

div.ListItem[clickable="false"]{
    cursor: default;
}

div.ListItem .text-wrapper * {
    text-align: left;
}





div.ListItem:active:not([clickable="false"]) .circle,
div.ListItem[data-selected="true"] .circle{
    border-color: var(--main-color);
}

div.ListItem:active:not([clickable="false"]) .circle .fill,
div.ListItem[data-selected="true"] .circle .fill{
    opacity: 1;
    background: var(--main-color);
    border: 1.5px var(--tint-color) solid;
}

div.ListItem:active:not([clickable="false"]) >.fill,
div.ListItem[data-selected="true"] >.fill{
    opacity: 1;
    height: 100px;
    transition: all ease 0.2s;
}

div.ListItme:active:not([clickable="false"]) img.icon,
div.ListItme[data-selected="true"] img.icon{
    filter:invert(100%);
}


div.ListItem:active:not([clickable="false"]) p,
div.ListItem[data-selected="true"] p{
    color:var(--main-color)
}

div.ListItem:active:not([clickable="false"]) h2,
div.ListItem[data-selected="true"] h2{
    color:var(--main-color);
}
div.ListItem:active:not([clickable="false"]) h3,
div.ListItem[data-selected="true"] h3{
    color:var(--main-color);
}


div.ListItem:active:not([clickable="false"]) img.options,
div.ListItem[data-selected="true"] img.options{
    filter:invert(100%);
}
div.ListItem:active:not([clickable="false"]) img.icon,
div.ListItem[data-selected="true"] img.icon{
    filter:invert(100%);
}





div.ListItem[level="error"]:active *,
div.ListItem[level="error"][data-selected="true"] *{
    color:var(--main-color);
}

div.ListItem img.thumbnail + img.icon {
    position: absolute;
    top:calc(50% + 25px - 7.5px);
    left:50px;
    width:12px;
    height:12px;
    border:2.5px var(--main-color) solid;
    border-radius: 50%;

}


.viewportModal div.ListItem img.thumbnail + img.icon {
    border-color: var(--secondary-main-color);
}

div.ListItem:active:not([clickable="false"]) img.thumbnail + img.icon,
div.ListItem[data-selected="true"] img.thumbnail + img.icon{
    filter:none;
    border:white solid 2.5px;
}

div.ListItem .badge{
    display: inline-block;
    font-size:10px;
    color:white;
    background: var(--success-color);
    margin-left:5px;
    margin-right:5px;
    padding:2px;
    margin-top:-2px;
    vertical-align: middle;
}








@keyframes ListItem_anim {
    0%{
        transform: translateX(100px);
        opacity: 0;
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
}





