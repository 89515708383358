:root {
    --main-color:black;
    --secondary-main-color:rgb(12, 12, 12);/*rgb(10,10,10);*/
    --terciary-main-color:rgb(45,45,45);
    --tint-color:white;
    --error-color:#ff382e;
    --success-color:#4cd964;
    --warn-color:#ffcc00;
    --info-color:#059bff;
}

*{
    font-family:'font' !important;
    text-transform:uppercase;
    color: var(--tint-color);
    font-weight: 400 !important;
}

@font-face{
    font-family: 'font';
    src:url('Font.otf');
}



html,body,.App,.root,#root{
    padding : 0;
    margin : 0;
    border: none;
    width:100vw;
    height:100%;
    height: -webkit-fill-available;
    position: fixed;
    top:0;
    left:0;
    border: 0;
    overflow: hidden;
    background: var(--main-color);
}

body{
    top:calc(0 - env(safe-area-inset-top)) !important;
    height:100vh !important;
}
  
  *:not(input):not(select):not(button){
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-callout: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-modify: none;
    -webkit-highlight: none;
}
  
*{
    font-family:Helvetica;
    -webkit-highlight: none;
    -webkit-user-drag: none;
    -webkit-touch-callout: none;
    -webkit-user-callout: none;
}

img{
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
  
    user-drag: none;
    -webkit-user-drag: none;
}
/*
h1,h2,h3,h4,h5,h6{
    user-select: none;
    -webkit-user-select: none;
    cursor: default;
    font-weight: 500;
    color:var(--tint-color);
}*/

canvas{
    width:100% !important;
    object-fit: contain !important;
}