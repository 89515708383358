.bottombar{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height: auto;

    padding:0;
    box-sizing: content-box;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    z-index:0;
}