
form{
  display: block;
  width:100%;
  padding:20px;
  box-sizing: border-box;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

#login form{
  vertical-align: middle;
}

form .error{
  color:var(--error-color);
  text-align: center;
  width:100%;
  font-size: 18px;
}


