.navbar{
    display: flex; 
    flex-direction: row;
    vertical-align: middle;
    position: relative;
    text-align: left;
    top:-10px;
    left:0;
    width:100% !important;
    height:80px;
    margin:0;
    padding:0;
}
.navbar .title{
    position: relative;
    display: inline;
    margin:20px;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;
}

.navbar .title h1{
    font-size:25px;
    font-weight: bold;
    padding:0;
    margin:0 !important;
    width:auto;
}
.navbar .title h2{
    font-size:15px;
    font-weight: bold;
    padding:0;
    margin:0 !important;
    width:auto;
    opacity: 0.5;
}

.navbar h1 img{
    height:30px;
    object-fit: contain;
}
.navbar > .navbutton{
    display: inline;
    margin:0;
    margin-left:10px;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;
    width:fit-content;
}

.navbar > .navbutton[data-pos="right"]{
    position: absolute;
    right:10px;
    top:15px;
}

.navbar  img.button{
    display: inline;
    object-fit: contain;
    padding:10px;
    box-sizing: border-box;
    height:40px;
    width:40px;
    transition: opacity 0.2s ease;
    opacity: 1;
}

.navbar img.button:active{
    opacity: 0.5;
}

.navbar .title h1 img.logotext{
    height:24px;
    margin-top:-10px;
    vertical-align: middle;
    box-sizing: border-box;
    object-fit: contain;
}

.navbar .title h1[align=center]{
    width: 100%;
    text-align: center;
    margin:0;
    padding:0;
    left:0;
    top:0;
}

.navbar .title:has(h1[align=center]){
    width: 100%;
}

