.viewportModal
{
    background:var(--secondary-main-color);
    position: absolute;
    top:10%;
    left:0;
    width:100%;
    height:90%;
    display: block;
}

@media screen and (min-width:700px) {
    .viewportModal{
        background:var(--secondary-main-color);
        position: absolute;
        top:15%;
        left:calc(50% - 350px);
        height:70%;
        width:100%;
        max-width:700px;
    }

    .viewportModal[data-size="small"]{
        max-width:500px;
        left:calc(50% - 250px);
    }
}