.viewport 
{
    background: var(--main-color);
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display: block;
    overflow: hidden;
}

.viewport[override="true"]{
    z-index:999;
}