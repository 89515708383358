.ListItemOrganisation{
    position: relative;
    display: block;
    width: 100%;
    height:140px;
    margin-top:-20px;
    margin-bottom: -20px;

    background: transparent;
    color:var(--main-color);

    transition: all ease 0.2s;

    cursor: pointer;
}



.ListItemOrganisation h2{
    font-size:18px;
    padding:0;
    margin:0;
    position: absolute;
    top:52px;
    left:60px;
    transition: all ease 0.2s;
}

.ListItemOrganisation h3{
    font-size: 13px;
    padding:0;
    margin:0;
    top:72px;
    left:60px;
    opacity: 0.5;
    position: absolute;
    transition: all ease 0.2s;
}

.ListItemOrganisation .circle{
    height:10px;
    width:10px;
    border: 1px solid var(--tint-color) ;
    background: transparent;
    border-radius: 50%;
    position: absolute;
    top:65px;
    left:30px;
    transition: all ease 0.2s;
    
}

.ListItemOrganisation .circle .fill{
    height:10px;
    width:10px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1.5px transparent solid;

    opacity: 0;
    transition: all ease 0.2s;
}

.ListItemOrganisation >.fill{
    opacity: 0;
    background: var(--tint-color);
    height:0px;
    position: absolute;
    top:50%;
    left:0;
    width: 100%;
    transition: all ease 0.2s;
    transform: translateY(-50%);
}

.ListItemOrganisation[data-selected="true"] .circle{
    border-color: var(--main-color);
}

.ListItemOrganisation[data-selected="true"] .circle .fill{
    opacity: 1;
    background: var(--main-color);
    border: 1.5px var(--tint-color) solid;
}

.ListItemOrganisation[data-selected="true"] >.fill{
    opacity: 1;
    height: 140px;
    transition: all ease 0.2s;
}



.ListItemOrganisation[data-selected="true"] h2{
    color:var(--main-color);
}
.ListItemOrganisation[data-selected="true"] h3{
    color:var(--main-color);
}

.ListItemOrganisation:not([data-selected="true"]):active{
    opacity: 0.5;
}

.ListItemOrganisation[style]{
    animation: ListItemOrganisation_anim 0.2s ease-in-out forwards;
    opacity: 0;
}

@keyframes ListItemOrganisation_anim {
    0%{
        transform: translateX(100px);
        opacity: 0;
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
}





