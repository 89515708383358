.ListItemEvent{
    position: relative;
    display: block;
    width: 100%;
    height:100px;
    margin-top:-20px;
    margin-bottom: -20px;

    background: transparent;
    color:var(--main-color);

    transition: all ease 0.2s;

    cursor: pointer;
}



.ListItemEvent h2{
    font-size:18px;
    padding:0;
    margin:0;
    position: absolute;
    top:32px;
    left:20px;
    transition: all ease 0.2s;
}

.ListItemEvent h3{
    font-size: 13px;
    padding:0;
    margin:0;
    top:52px;
    left:20px;
    opacity: 0.5;
    position: absolute;
    transition: all ease 0.2s;
}

.ListItemEvent .icon{
    height:10px;
    width:10px;
    position: absolute;
    top:45px;
    right:20px;
    transition: all 0.2s ease;
    object-fit: contain;
    filter:invert(0);
}


.ListItemEvent >.fill{
    opacity: 0;
    background: var(--tint-color);
    height:0px;
    position: absolute;
    top:50%;
    left:0;
    width: 100%;
    transition: all ease 0.2s;
    transform: translateY(-50%);
}



.ListItemEvent:active >.fill{
    opacity: 1;
    height: 140px;
    transition: all ease 0.2s;
}

.ListItemEvent:active  *{
    color:var(--main-color);
}

.ListItemEvent:active img.icon{
    filter:invert(100%);
}



.ListItemEvent[style]{
    animation: ListItemEvent_anim 0.2s ease forwards;
    opacity: 0;
}

@keyframes ListItemEvent_anim {
    0%{
        transform: scale(0.95);
        opacity: 0;
    }

    100%{
        opacity: 1;
        transform: scale(1);
    }
}





