.select{
    position: relative;
}

.select select{
    transition: border 0.4s ease;
    margin: 0;
    width:calc(100% - 2px);
    height:45px;
    padding-left:10px;
    padding-right:10px;
    font-size: 13px;
    box-sizing: border-box;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom:1px solid rgba(128,128,128,0.5);
    color:var(--tint-color);

}

.select select::placeholder{
    font-size:13px;
    color: var(--tint-color);
    opacity: 0.5;
}

.select select:focus{
    border-bottom-color: var(--tint-color);
}

.select select:-webkit-autofill,
.select select:-webkit-autofill:hover,
.select select:-webkit-autofill:active,
.select select:-webkit-autofill:focus{
    border-bottom:solid 1px var(--tint-color);
    -webkit-box-shadow: 0 0 0 60px var(--main-color) inset !important;
    background-color: var(--main-color) !important;
    background-clip: content-box !important;

    -webkit-box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-text-fill-color:var(--tint-color) !important;

    box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-text-fill-color:var(--tint-color) !important;
}

.select .error{
    position: absolute;
    top:calc(100% + 10px);
    left:10px;
    margin:0;
    padding:0;
    font-size: 15px;
    color:var(--error-color);
    width:calc(100% - 20px);
    text-align: left;
}

.select label{
    position: absolute;
    top:calc(12.5px);
    left:10px;
    margin:0;
    padding:0;
    text-align: left;
    font-size: 15px;
    color:var(--error-color);
    width:20%;
    color:var(--tint-color);
}

.select label + select{
    margin-left:20%;
    width:calc(80% - 2px);
}

.select select[level="error"]{
    border-color:var(--error-color);
    color:var(--error-color);
}
.select select[level="error"]::placeholder{
    color:var(--error-color);
}

.select::after{
    content:'⏷';
    color:1px solid rgba(128,128,128,0.5);
    opacity: 0.5;
    display: block;
    position: absolute;
    font-size:15px;
    top:15px;
    right:10px;
}

.select:has(select:focus)::after{
    color:var(--tint-color);
} 