.loading{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: var(--main-color);
}

.loading[background-transparent="true"]{
    background: transparent;
}

.loading .content{
    position: absolute;
    top:calc(50% - 110px);
    left:50%;
    transform: translateX(-50%);
    height: 110px;
    width:250px;
    border:0;
    padding:0;
    margin:0;
    overflow: hidden;
}

.loading .content img.logo{
    position: absolute;
    height:70px;
    width:70px;
    top:20px;
    left:-10px;
}


.loading .content img.logo[spin]{
    animation:spinner linear 0.7s infinite;
}

@keyframes spinner {
    0%{
        transform: rotate(0);
    }
    100%{
        transform:  rotate(359deg);
    }
}

.loading .content .text{
    position: absolute;
    left:60px;
    width: calc(100% - 120px);
    top:50%;
    transform: translate(0,-50%);
    height:auto;
    margin:0;
    text-align: left;
    padding:0;
    border:0;
    
}

.loading .content .text h2{
    font-size: 18px;
    width: 100%;
    margin:0;
    padding:0;
}

.loading .content .text h3{
    margin-top:10px;
    font-size: 11px;
    margin:0;
    padding:0;
    opacity: 0.3;

}

.loading .content .bar{
    position: absolute;
    bottom: 0;
    left:0;
    width:100%;
    height:1px;
    background: var(--tint-color);
    opacity: 0.3;
}

.loading .content .progress{
    position: absolute;
    bottom: 0;
    left:0;
    width:100%;
    height:1px;
    background: var(--tint-color);
    width: 10%;
}

@keyframes loadbar {
    0%{
        left:-30%;
    }
    100%{
        left:100%;
    }
}

div.loader-cube-container{
    position: absolute;
    top:35px;
    left:5px;
    display: inline-block;
    height:40px;
    width:40px;
    filter:blur(0px);
    
}

div.loader-cube-container div.cube{
    height:5px;
    width:5px;
    margin:2.5px;
    background: white;
    position: absolute;
    animation: loader-cube-animation ease 0.8s infinite;
    opacity: 0.3;
    box-shadow: white 0 0 0px 0px;
}
div.loader-cube-container.circle div.cube{
    height:5px;
    width:5px;
    margin:2.5px;
    background: white;
    position: absolute;
    border-radius: 50%;
    animation: loader-cube-animation ease 0.8s infinite;
    opacity: 0.3;
}

div.loader-cube-container.diagonal{
    transform:translate(-50%,-50%)  rotate(-45deg);
}

div.loader-cube-container div.cube:nth-of-type(1){
    top:0px;
    left:0px;
    animation-delay:0s ;
}
div.loader-cube-container div.cube:nth-of-type(2){
    top:0px;
    left:10px;
    animation-delay:0.2s ;
}
div.loader-cube-container div.cube:nth-of-type(3){
    top:0px;
    left:20px;
    animation-delay:0.4s ;
}
div.loader-cube-container div.cube:nth-of-type(4){
    top:0px;
    left:30px;
    animation-delay:0.6s ;
}
div.loader-cube-container div.cube:nth-of-type(5){
    top:10px;
    left:0px;
    animation-delay:0.2s ;
}
div.loader-cube-container div.cube:nth-of-type(6){
    top:10px;
    left:10px;
    animation-delay:0.4s ;
}
div.loader-cube-container div.cube:nth-of-type(7){
    top:10px;
    left:20px;
    animation-delay:0.6s ;
}
div.loader-cube-container div.cube:nth-of-type(8){
    top:10px;
    left:30px;
    animation-delay:0.8s ;
}
div.loader-cube-container div.cube:nth-of-type(9){
    top:20px;
    left:0px;
    animation-delay:0.4s ;
}
div.loader-cube-container div.cube:nth-of-type(10){
    top:20px;
    left:10px;
    animation-delay:0.6s ;
}
div.loader-cube-container div.cube:nth-of-type(11){
    top:20px;
    left:20px;
    animation-delay:0.8s ;
}
div.loader-cube-container div.cube:nth-of-type(12){
    top:20px;
    left:30px;
    animation-delay:1s ;
}
div.loader-cube-container div.cube:nth-of-type(13){
    top:30px;
    left:0;
    animation-delay:0.6s ;
}
div.loader-cube-container div.cube:nth-of-type(14){
    top:30px;
    left:10px;
    animation-delay:0.8s ;
}
div.loader-cube-container div.cube:nth-of-type(15){
    top:30px;
    left:20px;
    animation-delay:1s
}
div.loader-cube-container div.cube:nth-of-type(16){
    top:30px;
    left:30px;
    animation-delay:1.2s ;
}

@keyframes loader-cube-animation {
    0%{
        box-shadow: white 0 0 0px 0px;
        opacity: 0.3;
    }
    33%{
        box-shadow: white 0 0 10px 0px;
        opacity: 1;
    }
    66%{
        box-shadow: white 0 0 0px 0px;
        opacity: 0.3;
    }
    100%{
        box-shadow: white 0 0 0px 0px;
        opacity: 0.3;
    }
}