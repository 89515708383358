.ErrorScreen {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    background:var(--main-color);
}

.ErrorBlock{
    position: relative;
    width:100%;
    height:100px;
    box-sizing: border-box;
}

.ErrorScreen .ErrorBlock{
    position: absolute;
    top:0;
    left:0;
    height:100%;
    width: 100%;
    margin:0;
}

.ErrorContent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    max-width: 50%;
    overflow: hidden;
}

.ErrorBlock .ErrorContent h2{
    font-size: 22px;
}
.ErrorBlock .ErrorContent h3{
    font-size: 13px;
    opacity: 1;
}



.ErrorBlock .ErrorContent:before,
.ErrorBlock .ErrorContent:after,
.ErrorBlock .ErrorContent>:first-child:before,
.ErrorBlock .ErrorContent>:first-child:after {
    position: absolute;
    aspect-ratio: 1;
    height: 17%;
    border-color: var(--error-color);
    border-style: solid;
    content: ' ';
}


.ErrorBlock .ErrorContent:before {
    top: 0;
    left: 0;
    border-width: 2px 0 0 2px
}
.ErrorBlock .ErrorContent:after {
    top: 0;
    right: 0;
    border-width: 2px 2px 0 0
}

.ErrorBlock .ErrorContent>:first-child:before {
    bottom: 0;
    right: 0;
    border-width: 0 2px 2px 0
}
.ErrorBlock .ErrorContent>:first-child:after {
    bottom: 0;
    left: 0;
    border-width: 0 0 2px 2px
}

.ErrorContent{
    animation: ErrorContent_anim 0.2s linear;
}

.ErrorContent h3,
.ErrorContent h2{
    animation: ErrorContentText_anim 0.5s linear;
}

.ErrorContent h3{
    animation-duration: 0.55s;
}


.ErrorBlock *{
    color:var(--error-color);
    text-align: center;
    margin:0;
}

.ErrorBlock[level="error"] * {
    color:var(--error-color);
    border-color: var(--error-color);
}
.ErrorBlock[level="error"] .ErrorContent:before,
.ErrorBlock[level="error"] .ErrorContent:after,
.ErrorBlock[level="error"] .ErrorContent>:first-child:before,
.ErrorBlock[level="error"] .ErrorContent>:first-child:after{
    border-color: var(--error-color);
}

.ErrorBlock[level="success"] *{
    color:var(--success-color);
    border-color: var(--success-color);
}
.ErrorBlock[level="success"] .ErrorContent:before,
.ErrorBlock[level="success"] .ErrorContent:after,
.ErrorBlock[level="success"] .ErrorContent>:first-child:before,
.ErrorBlock[level="success"] .ErrorContent>:first-child:after{
    border-color: var(--success-color);
}

.ErrorBlock[level="info"] *{
    color:var(--tint-color);
    border-color: var(--tint-color);
}
.ErrorBlock[level="info"] .ErrorContent:before,
.ErrorBlock[level="info"] .ErrorContent:after,
.ErrorBlock[level="info"] .ErrorContent>:first-child:before,
.ErrorBlock[level="info"] .ErrorContent>:first-child:after{
    border-color: var(--tint-color);
}

.ErrorBlock[level="warn"] *{
    color:var(--warn-color);
    border-color: var(--warn-color);
}
.ErrorBlock[level="warn"] .ErrorContent:before,
.ErrorBlock[level="warn"] .ErrorContent:after,
.ErrorBlock[level="warn"] .ErrorContent>:first-child:before,
.ErrorBlock[level="warn"] .ErrorContent>:first-child:after{
    border-color: var(--warn-color);
}

@keyframes ErrorContent_anim {
    0%{
        max-height: 0;
        max-width: 0;
    }
    100%{
        max-height: 50%;
        max-width: 50%;
    }
}

@keyframes ErrorContentText_anim {
    0%{
        opacity: 0;
    }

    50%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
    
}