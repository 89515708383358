.keypad-container{
    display: block;
    width:100%;
    padding:20px;
    box-sizing: border-box;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.keypad-container .keypad{
    height:66%;
    width:100%;
    text-align: center;
    padding:6vw;
    padding-top:50px;
    box-sizing: border-box;
    
}

.keypad-container .keypad .key:active{
    filter:invert(100%);
}


.keypad-container .keypad .key{
    width:calc(33%);
    height:calc(33%);
    padding:calc(16.5% - 15px);
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    font-size: 30px;
    cursor: pointer;
    background: var(--main-color);
    transition: all 0.2s ease;
    filter:invert(0%);
}

.keypad-container .keypad .key img{
    height:20px;
    width:20px;
    object-fit: contain;
    vertical-align: middle;
    display: inline;
}

