.input{
    position: relative;
}

.input input{
    transition: border 0.4s ease;
    margin: 0;
    width:calc(100% - 2px);
    height:45px;
    padding-left:10px;
    padding-right:10px;
    font-size: 13px;
    box-sizing: border-box;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom:1px solid rgba(128,128,128,0.5);
    color:var(--tint-color);
}

.input[data-noborder="true"] input{
    border:1px solid transparent !important;
    font-size:16px;
}

.input input::placeholder{
    font-size:13px;
    color: var(--tint-color);
    opacity: 0.5;
}

.input input:focus{
    border-bottom-color: var(--tint-color);
}

.input input:-webkit-autofill,
.input input:-webkit-autofill:hover,
.input input:-webkit-autofill:active,
.input input:-webkit-autofill:focus{
    border-bottom:solid 1px var(--tint-color);
    -webkit-box-shadow: 0 0 0 60px var(--main-color) inset !important;
    background-color: var(--main-color) !important;
    background-clip: content-box !important;

    -webkit-box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-text-fill-color:var(--tint-color) !important;

    box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-text-fill-color:var(--tint-color) !important;
}

.input .error{
    position: absolute;
    top:calc(100% + 10px);
    left:10px;
    margin:0;
    padding:0;
    font-size: 15px;
    color:var(--error-color);
    width:calc(100% - 20px);
    text-align: left;
}

.input label:not([for]){
    position: absolute;
    top:calc(12.5px);
    left:10px;
    margin:0;
    padding:0;
    text-align: left;
    font-size: 15px;
    width:20%;
    color:var(--tint-color);
}

.input label:not([for]) + input{
    margin-left:20%;
    width:calc(80% - 2px);
}

.input input[level="error"]{
    border-color:var(--error-color);
    color:var(--error-color);
}
.input input[level="error"]::placeholder{
    color:var(--error-color);
}

.input textarea[level="error"]{
    border-color:var(--error-color);
    color:var(--error-color);
}
.input textarea[level="error"]::placeholder{
    color:var(--error-color);
}

.input textarea:-webkit-autofill,
.input textarea:-webkit-autofill:hover,
.input textarea:-webkit-autofill:active,
.input textarea:-webkit-autofill:focus{
    border-bottom:solid 1px var(--tint-color);
    -webkit-box-shadow: 0 0 0 60px var(--main-color) inset !important;
    background-color: var(--main-color) !important;
    background-clip: content-box !important;

    -webkit-box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-text-fill-color:var(--tint-color) !important;

    box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-box-shadow: 0 0 0 30px var(--main-color) inset !important;
    -webkit-text-fill-color:var(--tint-color) !important;
}

.input textarea{
    transition: border 0.4s ease;
    margin: 0;
    width:calc(100% - 2px);
    height:45px;
    padding-left:10px;
    padding-right:10px;
    font-size: 13px;
    box-sizing: border-box;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    background: transparent;
    border: none;
    border-radius: 0;
    resize:none;
    color:var(--tint-color);
    height:150px;
    text-transform: none;
}

.input input[type="file"]{
    position: absolute;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    height: 0;
    width:0;
    top:0;
    left:0;
    z-index:-1;
    vertical-align: baseline;
}

.input input[type="file"] + label{
    position: relative;
    width: 100%;
    height: 100%;
}
